<template>
  <div>
    <div class="ml-8 mb-4 mt-1">
      <v-icon color="#338A68" class="mr-2">
        mdi-checkbox-marked-circle-outline</v-icon
      >
      <span class=" font-weight-bold text-uppercase">{{ $route.name }}</span>
    </div>

    <div class=" mx-8">
      <v-layout wrap>
        <v-flex xs12>
          <!-- Form Search -->
          <v-card flat outlined>
            <v-form
              class="px-3 py-4"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-layout>
                <v-flex xs3>
                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs4 text-end  font-12 mr-3 font-weight-bold">
                      Job no. :
                    </v-flex>
                    <v-flex class="xs7 px-0">
                      <TextInput
                        :model="form.order_id"
                        rulse="fieldRequire"
                        @update-data="updateData('order_id', $event)"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs3>
                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs4 text-end  font-12 mr-3 font-weight-bold">
                      INV./PO* :
                    </v-flex>
                    <v-flex class="xs8 px-0">
                      <TextInput
                        :model="form.inv_po"
                        rulse="fieldRequire"
                        @update-data="updateData('inv_po', $event)"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs3>
                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs4 text-end  font-12 mr-3 font-weight-bold">
                      Booking :
                    </v-flex>
                    <v-flex class="xs8 px-0">
                      <TextInput
                        :model="form.booking_no"
                        rulse="fieldRequire"
                        @update-data="updateData('booking_no', $event)"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs3>
                  <v-layout class="align-center" mb-3>
                    <v-flex
                      class="xs4 text-end  font-12 mr-3 ml-n4 font-weight-bold"
                    >
                      Driver :
                    </v-flex>
                    <v-flex class="xs8 px-0">
                      <TextInput
                        :model="form.driver_name"
                        rulse="fieldRequire"
                        @update-data="updateData('driver_name', $event)"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs3>
                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                      Customer :
                    </v-flex>
                    <v-flex class="xs7 px-0">
                      <AutocompleteInput
                        clearable
                        :model="form.customer_id"
                        rulse="fieldRequire"
                        :items="customer"
                        itemText="customer_code"
                        itemValue="customer_id"
                        @update-data="
                          updateSelect('customer', 'customer_id', $event)
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs3>
                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                      Importer <br />
                      /Exporter :
                    </v-flex>
                    <v-flex class="xs8 px-0">
                      <AutocompleteInput
                        clearable
                        :model="form.importer_exporter_id"
                        rulse="fieldRequire"
                        :items="importerExporter"
                        itemText="customer_code"
                        itemValue="customer_id"
                        @update-data="
                          form.importer_exporter_id = $event
                            ? $event.customer_id
                            : ''
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs3>
                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                      CY Place :
                    </v-flex>
                    <v-flex class="xs8 px-0">
                      <AutocompleteInput
                        clearable
                        hideDetails
                        :model="form.cy_place_id"
                        rulse="fieldRequire"
                        :items="cyPlaceList"
                        itemText="cy_place_name"
                        itemValue="cy_place_id"
                        @update-data="
                          form.cy_place_id = $event ? $event.cy_place_id : ''
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs3>
                  <v-layout class="align-center" mb-2>
                    <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                      CY Date :
                    </v-flex>
                    <v-flex class="xs4 px-0">
                      <DatePicker
                        hideDetail
                        rulse="fieldRequire"
                        :model="date.cy_date_from"
                        @update-data="updateDate('cy_date_from', $event)"
                      ></DatePicker>
                    </v-flex>
                    <v-flex
                      class="shrink text-end font-12 mx-2 font-weight-bold"
                    >
                      To
                    </v-flex>
                    <v-flex class="xs4 px-0">
                      <DatePicker
                        hideDetail
                        rulse="fieldRequire"
                        :model="date.cy_date_to"
                        @update-data="updateDate('cy_date_to', $event)"
                      ></DatePicker>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs3>
                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
                      WH/Factory :
                    </v-flex>
                    <v-flex class="xs8">
                      <AutocompleteInput
                        clearable
                        hideDetail
                        :model="form.wh_factory_id"
                        rulse="fieldRequire"
                        :items="factoryList"
                        itemText="wh_factory_name"
                        itemValue="wh_factory_id"
                        @update-data="
                          updateSelect('wh_factory', 'wh_factory_id', $event)
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs3>
                  <v-layout class="align-center" mb-2>
                    <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                      Load <br />
                      /Unload Date :
                    </v-flex>
                    <v-flex class="xs4 px-0 pl-2">
                      <DatePicker
                        hideDetail
                        rulse="fieldRequire"
                        :model="date.load_and_unload_date_from"
                        @update-data="
                          updateDate('load_and_unload_date_from', $event)
                        "
                      ></DatePicker>
                    </v-flex>
                    <v-flex
                      class="shrink text-end font-12 mx-2 font-weight-bold"
                    >
                      To
                    </v-flex>
                    <v-flex class="xs4 px-0">
                      <DatePicker
                        hideDetail
                        rulse="fieldRequire"
                        :model="date.load_and_unload_date_to"
                        @update-data="
                          updateDate('load_and_unload_date_to', $event)
                        "
                      ></DatePicker>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs3>
                  <v-layout class="align-center" mb-2>
                    <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                      Create <br />
                      /Update Order :
                    </v-flex>
                    <v-flex class="xs4 px-0 pl-2">
                      <DatePicker
                        hideDetail
                        rulse="fieldRequire"
                        :model="date.create_update_accept_tender_from"
                        @update-data="
                          updateDate('create_update_accept_tender_from', $event)
                        "
                      ></DatePicker>
                    </v-flex>
                    <v-flex
                      class="shrink text-end font-12 mx-2 font-weight-bold"
                    >
                      To
                    </v-flex>
                    <v-flex class="xs4 px-0">
                      <DatePicker
                        hideDetail
                        rulse="fieldRequire"
                        :model="date.create_update_accept_tender_to"
                        @update-data="
                          updateDate('create_update_accept_tender_to', $event)
                        "
                      ></DatePicker>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-spacer></v-spacer>
                <v-flex shrink class="justify-end">
                  <v-btn
                    color="#338A68"
                    dark
                    class="mr-3 font-weight-bold"
                    @click="onHandleNewJobToday()"
                  >
                    <span class="text-capitalize font-11">New job today</span>
                  </v-btn>
                </v-flex>
                <v-flex shrink class="justify-end">
                  <v-btn
                    color="#EC8235"
                    dark
                    class="mr-3 font-weight-bold"
                    @click="onHandleNonTender()"
                  >
                    <span class="text-capitalize font-11">Non Tender</span>
                  </v-btn>
                </v-flex>
                <v-flex shrink class="justify-end">
                  <v-btn
                    color="#9162DE"
                    dark
                    class="mr-3 font-weight-bold"
                    @click="onHandlePendingJob()"
                  >
                    <span class="text-capitalize font-11">Pending Job</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>

      <!-- Mode -->
      <v-card outlined width="100%" flat class="mt-4">
        <v-layout>
          <v-flex xs8>
            <v-layout class="align-center text-end">
              <v-flex xs2>Mode : </v-flex>
              <v-flex xs10 pl-6>
                <v-layout>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_import"
                      label="Import"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_export"
                      label="Export"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_empty"
                      label="Empty"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_laden"
                      label="Laden"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_drop_load"
                      label="Drop Load"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex shrink class="text-end pr-6">
            <v-btn
              color="#F7D25D"
              class="my-4 font-weight-bold text-capitalize"
              @click="onHandleSearch()"
            >
              <v-icon class="mr-3">mdi-magnify</v-icon>
              Search
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>

      <!-- button -->

      <v-layout my-4>
        <v-flex class="shrink mr-3">
          <v-btn color="#64C8E7" dark class="font-weight-bold" @click="print()">
            <v-icon class="mr-3">mdi-printer</v-icon>
            <span class="text-capitalize">Print</span>
          </v-btn>
        </v-flex>
        <v-flex class="shrink mr-3">
          <v-btn
            outlined
            color="#338A68"
            dark
            class="font-weight-bold"
            @click.prevent="createCostPerTruck()"
          >
            <span class="text-capitalize" style="color: #000">
              Create Cost/Truck
            </span>
          </v-btn>
        </v-flex>
        <v-flex class="shrink mr-3">
          <v-btn
            color="#338A68"
            dark
            class="font-weight-bold"
            @click.prevent="onChangeStatusJobAcceptTender('Complete')"
          >
            <span class="text-capitalize">
              Complete
            </span>
          </v-btn>
        </v-flex>
        <v-flex class="shrink mr-3">
          <v-btn
            color="#338A68"
            dark
            class="font-weight-bold"
            @click.prevent="onChangeStatusJobAcceptTender('Pending')"
          >
            <span class="text-capitalize">
              Pending
            </span>
          </v-btn>
        </v-flex>
        <v-flex class="shrink mr-3">
          <v-btn
            outlined
            depressed
            color="#338A68"
            dark
            class="font-weight-bold"
            @click="exportExcel()"
          >
            <v-icon class="mr-3">mdi-file-excel</v-icon>
            <span class="text-capitalize">Export Excel</span>
          </v-btn>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex class="shrink mr-3">
          <v-btn
            color="#338A68"
            dark
            class="font-weight-bold justify-end"
            @click="onHandleClickStatus('accept')"
          >
            <span class="text-capitalize">Accept</span>
          </v-btn>
        </v-flex>
        <v-flex class="shrink mr-3">
          <v-btn
            outlined
            color="#EB5757"
            dark
            class="font-weight-bold"
            @click="onHandleClickStatus('reject')"
          >
            <span class="text-capitalize" style="color: #000">Reject</span>
          </v-btn>
        </v-flex>

        <!-- ปุ่ม Cancel แอดมินจะเป็นได้คนเดียว => user_group_id = 7 -->
        <v-flex class="shrink mr-3" v-if="user.user_group_id === 7">
          <v-btn
            outlined
            color="#EB5757"
            dark
            class="font-weight-bold"
            @click="onHandleClickStatus('cancel')"
          >
            <span class="text-capitalize" style="color: #000">Cancel</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </div>

    <v-layout>
      <v-flex style="width: 1px">
        <DataTableAcceptTender
          nameTable="tender"
          :headers="headersAcceptTender"
          :headersChild="headersAcceptTenderChild"
          :items="dataAcceptTender"
          :addItem="addData"
          :deleteItem="deleteAcceptTender"
          :saveItem="saveAcceptTender"
          :editItem="editAcceptTender"
          :indexChild="indexChild"
          :indexMain="indexMain"
          :driverList="driverList"
          :truckLicenseList="truckLicenseList"
          :trailerLicenseList="trailerLicenseList"
          :acceptTenderItem="acceptTenderItem"
          :tenderItem="tenderItem"
          :onCancel="onCancel"
          :clearSelected="clearSelected"
          :isClearSelected="isClearSelected"
          :isClearSubSelected="isClearSubSelected"
          :isChangePaginateAndClearForm="isChangePaginateAndClearForm"
          :onHandleToPending="onHandleToPending"
        />
      </v-flex>
    </v-layout>

    <v-layout mt-4>
      <v-flex>
        <Paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :nextPage="search"
        />
      </v-flex>
    </v-layout>

    <!-- Dialog change status to reject -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="font-24 font-weight-bold">Reason of rejecting</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <label for="">Topic :</label>
                    <AutocompleteInput
                      clearable
                      placeholder="Customer"
                      v-model="reject_form.reason_topic_id"
                      rulse="fieldRequire"
                      :items="reason_list"
                      itemText="name"
                      itemValue="id"
                      @update-data="
                        reject_form.reason_topic_id = $event ? $event.id : '';
                        reject_form.reason_topic_name = $event
                          ? $event.name
                          : '';
                      "
                    />
                  </v-col>
                  <v-col cols="12">
                    <label for="">Detail :</label>
                    <v-textarea
                      v-model="reject_form.reason_detail"
                      color="#338A68"
                      dense
                      outlined
                      required
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="#338A68"
                dark
                outlined
                class="text-capitalize"
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="#338A68"
                dark
                class="text-capitalize"
                @click="changeStatus('reject')"
              >
                Reject
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import XLSX from "xlsx"; // import xlsx;
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextInput from "../../../components/input/TextInput.vue";
import AutocompleteInput from "../../../components/select/AutocompleteInput";
import DatePicker from "../../../components/picker/DatePicker";
import Paginate from "../../../components/pagination/Paginate.vue";
import DataTableAcceptTender from "../../../components/table/DataTableAcceptTender.vue";
import { mapState } from "vuex";
export default {
  components: {
    Loading,
    TextInput,
    AutocompleteInput,
    DatePicker,
    Paginate,
    DataTableAcceptTender,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  data() {
    return {
      itemAcceptTender: [],
      isChangePaginateAndClearForm: false,
      dialog: false,
      isClearSelected: false,
      isClearSubSelected: false,
      isLoading: false,
      fullPage: true,
      valid: true,
      paginate: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 1,
        total: 1,
        to: 1,
      },
      form: {
        order_id: "",
        inv_po: "",
        booking_no: "",
        customer_id: "",
        importer_exporter_id: "",
        create_update_accept_tender_from: "",
        create_update_accept_tender_to: "",
        wh_factory_id: "",
        driver_name: "",
        load_and_unload_date_from: "",
        load_and_unload_date_to: "",
        cy_date_from: "",
        cy_date_to: "",
        cy_place_id: "",
        mode: [],
        non_tender: "",
        new_job_today: "",
        pending_job: "",
      },
      date: {
        create_update_accept_tender_from: "",
        create_update_accept_tender_to: "",
        load_and_unload_date_from: "",
        load_and_unload_date_to: "",
        cy_date_from: "",
        cy_date_to: "",
      },
      listMode: [
        { label: "Import" },
        { label: "Export" },
        { label: "Empty" },
        { label: "Laden" },
        { label: "Drop Load" },
      ],
      importerExporter: [],
      customer: [],
      factoryList: [],
      driverList: [],
      truckLicenseList: [],
      trailerLicenseList: [],

      headersAcceptTender: [
        {
          text: "Select",
          value: "select",
          sortable: false,
          width: "50px",
          class: "border-right",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "100px",
          align: "center",
          class: "border-right",
        },
        {
          text: "No.",
          value: "no",
          align: "start",
          sortable: false,
          width: "5px",
          class: "",
        },
        {
          text: "Status",
          value: "status",
          align: "start",
          sortable: false,
          width: "130px",
          class: "",
        },
        {
          text: "Reason",
          value: "reason",
          align: "start",
          sortable: false,
          width: "150px",
          class: "",
        },
        {
          text: "Job No.",
          value: "job_no",
          align: "start",
          sortable: false,
          width: "150px",
          class: "",
        },
        {
          text: "Vendor",
          value: "vendor",
          align: "start",
          sortable: false,
          width: "200px",
          class: "",
        },
        {
          text: "Customer",
          value: "customer",
          align: "start",
          sortable: false,
          width: "200px",
          class: "",
        },
        {
          text: "Inv./PO NO.*",
          align: "inv_no",
          sortable: false,
          width: "200px",
          class: "",
        },
        {
          text: "Booking",
          value: "booking",
          align: "start",
          sortable: false,
          width: "20px",
          class: "",
        },
        {
          text: "CY Place",
          value: "cy_place",
          align: "start",
          sortable: false,
          width: "250px",
          class: "",
        },
        {
          text: "CY Date",
          value: "cy_date",
          align: "start",
          sortable: false,
          width: "130px",
          class: "",
        },
        {
          text: "Release Date",
          value: "release_date",
          align: "start",
          sortable: false,
          width: "120px",
          class: "",
        },
        {
          text: "Load/Unload Date 1",
          value: "unload_load_date1",
          align: "start",
          sortable: false,
          width: "120px",
          class: "",
        },
        {
          text: "Model",
          value: "model_name",
          align: "center",
          sortable: false,
          width: "100px",
        },
        {
          text: "WH/Factory 1",
          value: "wh_factory1",
          align: "start",
          sortable: false,
          width: "180px",
          class: "",
        },
        {
          text: "Load/Unload Date 2",
          value: "unload_load_date2",
          align: "start",
          sortable: false,
          width: "120px",
          class: "",
        },
        {
          text: "WH/factory 2",
          value: "wh_factory2",
          align: "start",
          sortable: false,
          width: "180px",
          class: "",
        },
        {
          text: "Qty",
          value: "qty",
          align: "start",
          sortable: false,
          width: "120px",
          class: "",
        },
        {
          text: "Size Container",
          value: "size_container",
          align: "center",
          sortable: false,
          width: "120px",
          class: "",
        },
        {
          text: "IsoType",
          value: "iso_type",
          align: "center",
          sortable: false,
          width: "120px",
          class: "",
        },
        {
          text: "Return Place",
          value: "return_place",
          align: "start",
          sortable: false,
          width: "200px",
          class: "",
        },
        {
          text: "Closing Date",
          value: "closing_date",
          align: "center",
          sortable: false,
          width: "120px",
          class: "",
        },
        {
          text: "Closing Time",
          value: "closing_time",
          align: "center",
          sortable: false,
          width: "120px",
          class: "",
        },
        {
          text: "FRD",
          value: "frd",
          align: "center",
          sortable: false,
          width: "120px",
          class: "",
        },
        {
          text: "Agent",
          value: "agent",
          align: "start",
          sortable: false,
          width: "250px",
          class: "",
        },
        {
          text: "Shipping",
          value: "shipping",
          align: "start",
          sortable: false,
          width: "250px",
          class: "",
        },
        {
          text: "Create/Update Order",
          value: "shipping",
          align: "start",
          sortable: false,
          width: "120px",
          class: "",
        },
        {
          text: "User Update",
          value: "user_update",
          align: "start",
          sortable: false,
          width: "100px",
          class: "",
        },
      ],
      headersAcceptTenderChild: [
        // {
        //   text: "",
        //   value: "select",
        //   sortable: false,
        //   width: "25px",
        // },
        {
          text: "Acion",
          value: "actions",
          sortable: false,
          width: "100px",
          type: "action",
          align: "center",
        },
        {
          text: "No.",
          align: "center",
          sortable: false,
          width: "100px",
          class: "ml-n16",
        },
        {
          text: "Create Cost/Truck Date",
          align: "center",
          sortable: false,
          width: "200px",
        },
        {
          text: "Unload /Load Date",
          value: "unload_load_date",
          align: "center",
          sortable: false,
          width: "200px",
        },
        {
          text: "WH/Factory 1",
          value: "wh_factory1",
          align: "center",
          sortable: false,
          width: "250px",
        },
        {
          text: "Qty",
          value: "qty",
          align: "center",
          sortable: false,
          width: "100px",
        },
        {
          text: "Driver",
          value: "driver",
          align: "center",
          sortable: false,
          width: "200px",
        },
        {
          text: "Truck License",
          value: "truck_license",
          align: "center",
          sortable: false,
          width: "200px",
        },
        {
          text: "Trailer License",
          value: "truck_license",
          align: "center",
          sortable: false,
          width: "200px",
        },
        {
          text: "Job Status",
          align: "center",
          sortable: false,
          width: "150px",
        },
        {
          text: "",
          value: "save_new",
          align: "center",
          sortable: false,
          width: "100px",
        },
        { text: "", width: "2000px", sortable: false },
      ],
      dataAcceptTender: [],
      indexMain: "",
      indexChild: null,
      accept_tenders: [],
      tenders_id: [],
      selectItem: [],
      dataExcel: [],
      checkData: true,
      reason_list: [
        { id: 1, name: "คนไม่พอ" },
        { id: 2, name: "ไม่มีประสบการณ์ในการทํางาน" },
        { id: 3, name: "กําลังรถไม่พอ" },
        { id: 4, name: "ยกเลิก Job" },
      ],
      reject_form: {
        reason_topic_id: "",
        reason_topic_name: "",
        reason_detail: "",
        status: "",
      },
      cyPlaceList: [],
      is_import: false,
      is_export: false,
      is_empty: false,
      is_laden: false,
      is_drop_load: false,
    };
  },
  created() {
    this.isLoading = true;
    Promise.all([
      this.getData(1),
      this.getMasterData(),
      this.getTruckSupplyActive(),
    ])
      .then((values) => {
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
  },
  watch: {
    selectItem: {
      handler: function(newValue) {
        if (newValue.length == 0) {
          this.isClearSelected = false;
          this.checkData = true;
          this.dataExcel = [];
        }
      },
    },
    is_import: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Import") === -1) {
            this.form.mode.push("Import");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Import"), 1);
        }
      },
    },
    is_export: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Export") === -1) {
            this.form.mode.push("Export");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Export"), 1);
        }
      },
    },
    is_empty: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Empty") === -1) {
            this.form.mode.push("Empty");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Empty"), 1);
        }
      },
    },
    is_laden: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Laden") === -1) {
            this.form.mode.push("Laden");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Laden"), 1);
        }
      },
    },
    is_drop_load: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Drop Load") === -1) {
            this.form.mode.push("Drop Load");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Drop Load"), 1);
        }
      },
    },
  },
  methods: {
    async getData(page) {
      const res = await this.$store.dispatch("accept_tender/getData", page);
      //! เลข no table
      res.data.data = res.data.data.map((data, i) => ({
        ...data,
        no: (res.data.current_page - 1) * res.data.per_page + i + 1,
      }));
      // this.itemAcceptTender = res.data;
      this.dataAcceptTender = res.data.data;
      this.paginate.current_page = res.data.current_page;
      this.paginate.last_page = res.data.last_page;
    },
    addData(item) {
      this.indexMain = item.tender_id;
      this.indexChild = item.accept_tender.length;
      // this.dataAcceptTender[index].accept_tender.push({
      item.accept_tender.push({
        accept_tender_id: "",
        tender_id: item.tender_id,
        carrier_id: item.carrier_id,
        carrier_name: item.carrier_name,
        truck_supply_id: "",
        unload_load_date1: item.order_detail.unload_load_date1,
        wh_factory_1_id: item.order_detail.wh_factory_1_id,
        wh_factory_1_name: item.order_detail.wh_factory_1_name,
        // qty: item.qty,
        qty: 0,
        driver_id: "",
        driver_name: "",
        truck_license_id: "",
        truck_license_name: "",
        trailer_license_id: "",
        trailer_license_name: "",
        job_status_id: "1",
        job_status_name: "Draft",
      });
    },
    async saveAcceptTender(item, typeSave) {
      item.trucks = undefined;
      item.trailers = undefined;
      this.indexChild = null;
      this.isLoading = true;
      await this.$store
        .dispatch("accept_tender/save", item)
        .then((res) => {
          this.isLoading = false;
          if (!res.data.success) {
            this.$swal
              .fire({
                icon: "error",
                title: "Save Failed",
                text: res.data.data.error,
              })
              .then(() => {
                this.search(this.paginate.current_page);
                // this.getData(1);
              });
          } else {
            this.isLoading = false;
            this.$swal
              .fire({
                icon: "success",
                title: "Save Accept Tender successfully!",
                showConfirmButton: false,
                timer: 1500,
              })
              .then(() => {
                this.isLoading = true;
                this.search(this.paginate.current_page);
                // window.location.reload();
              });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal
            .fire({
              icon: "error",
              title: "Save Failed",
              text: "เกิดข้อผิดพลาดบางอย่าง",
            })
            .then(() => {
              this.search(this.paginate.current_page);
              // this.getData(1);
            });
        });
    },
    editAcceptTender(item, index) {
      this.indexMain = item.tender_id;
      this.dataAcceptTender.find((element) => {
        if (element.tender_id === item.tender_id) {
          this.indexChild = index;
        }
      });
    },
    deleteAcceptTender(item, i) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            this.$store
              .dispatch("accept_tender/delete", item.accept_tender_id)
              .then((res) => {
                this.isLoading = false;

                this.dataAcceptTender.find((element) => {
                  if (element.tender_id === item.tender_id) {
                    element.accept_tender = element.accept_tender.filter(
                      function(val, key) {
                        return key !== i;
                      }
                    );
                  }
                });

                this.$swal.fire({
                  icon: "success",
                  title: "Delete successfully!",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch((error) => {
                this.$swal.fire({
                  title: "Something went wrong!",
                  text: error.response.data.data.error,
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "OK",
                });
                this.isLoading = false;
              });
          }
        });
    },
    updateData(name, val) {
      this.form[name] = val;
    },
    updateDate(name, val) {
      this.form[name] = val ? val : "";
      this.date[name] = val ? this.customDate(val) : "";
    },
    updateSelect(name, key, val) {
      if (val) {
        this.form[name + "_id"] = val[key];
      } else {
        this.form[name + "_id"] = "";
      }
    },
    customDate(val) {
      let sub_year = val.substring(2, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      // sub_year = parseInt(sub_year) + 543;
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },
    // Search
    onHandleSearch() {
      this.form.new_job_today = "";
      this.form.non_tender = "";
      this.form.pending_job = "";
      this.search(1);
    },
    search(page) {
      this.isChangePaginateAndClearForm = true;
      this.isLoading = true;
      this.form["page"] = page;
      this.getTruckSupplyActive();
      this.$store
        .dispatch("accept_tender/search", this.form)
        .then((res) => {
          this.isLoading = false;
          this.isChangePaginateAndClearForm = false;
          //! เลข no table
          res.data.data = res.data.data.map((data, i) => ({
            ...data,
            no: (res.data.current_page - 1) * res.data.per_page + i + 1,
          }));
          this.dataAcceptTender = res.data.data;
          this.paginate.current_page = res.data.current_page;
          this.paginate.last_page = res.data.last_page;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    onHandleNewJobToday() {
      this.form = {
        order_id: "",
        inv_po: "",
        booking_no: "",
        customer_id: "",
        importer_exporter_id: "",
        create_update_accept_tender_from: "",
        create_update_accept_tender_to: "",
        wh_factory_id: "",
        driver_name: "",
        load_and_unload_date_from: "",
        load_and_unload_date_to: "",
        cy_date_from: "",
        cy_date_to: "",
        cy_place_id: "",
        mode: [],
        non_tender: "",
        new_job_today: "new job today",
        pending_job: "",
      };
      this.search(1);
    },
    onHandleNonTender() {
      this.form = {
        order_id: "",
        inv_po: "",
        booking_no: "",
        customer_id: "",
        importer_exporter_id: "",
        create_update_accept_tender_from: "",
        create_update_accept_tender_to: "",
        wh_factory_id: "",
        driver_name: "",
        load_and_unload_date_from: "",
        load_and_unload_date_to: "",
        cy_date_from: "",
        cy_date_to: "",
        cy_place_id: "",
        mode: [],
        new_job_today: "",
        non_tender: "non tender",
        pending_job: "",
      };
      this.search(1);
    },
    onHandlePendingJob() {
      this.form = {
        order_id: "",
        inv_po: "",
        booking_no: "",
        customer_id: "",
        importer_exporter_id: "",
        create_update_accept_tender_from: "",
        create_update_accept_tender_to: "",
        wh_factory_id: "",
        driver_name: "",
        load_and_unload_date_from: "",
        load_and_unload_date_to: "",
        cy_date_from: "",
        cy_date_to: "",
        cy_place_id: "",
        mode: [],
        non_tender: "",
        new_job_today: "",
        pending_job: "pending job",
      };
      this.search(1);
    },
    acceptTenderItem(item) {
      this.accept_tenders = item;
    },
    tenderItem(item) {
      this.tenders_id = item.map((data) => ({
        tender_id: data.tender_id,
        status: data.status,
      }));
      this.selectItem = item;
    },
    clearSelected(val) {
      this.isClearSelected = val;
      this.isClearSubSelected = val;
    },

    onHandleClickStatus(status) {
      if (this.tenders_id.length > 0) {
        //! Case reject
        if (status === "reject") {
          for (const data of this.tenders_id) {
            if (data.status !== null) {
              this.$swal.fire({
                title: "Something went wrong!",
                text:
                  "ไม่สามารถ Reject ได้ เนื่องจากมีรายการที่มีสถานะอยู่แล้ว",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
              return false;
            }
          }
          this.dialog = true;
        }

        //! Case accept
        else if (status === "accept") {
          for (const data of this.tenders_id) {
            if (data.status !== null) {
              this.$swal.fire({
                title: "Something went wrong!",
                text:
                  "ไม่สามารถ Accept ได้ เนื่องจากมีรายการที่มีสถานะอยู่แล้ว",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
              return false;
            }
          }
          this.changeStatus(status);
        }

        //! Case cancel
        else if (status === "cancel") {
          this.changeStatus(status);
        }

        //! Other Case
        else {
          this.$swal.fire({
            title: "Something went wrong!",
            text: "เกิดข้อผิดพลาดบางอย่าง กรุณาตรวจสอบ",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
        }
      } else {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select 1 order before click button",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },

    // Change Status Tender
    changeStatus(status) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Do You Want to Change Status ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Change it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let data = this.tenders_id.map((data) => {
              return {
                ...data,
                ...this.reject_form,
                status: status,
              };
            });
            this.isLoading = true;
            this.isClearSelected = true;
            this.$store
              .dispatch("accept_tender/changeStatus", {
                data: data,
              })
              .then((res) => {
                this.isLoading = false;
                this.dialog = false;
                this.isClearSelected = false;
                this.$swal
                  .fire({
                    icon: "success",
                    title: "Change successfully!",
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then(() => {
                    this.isLoading = true;
                    this.search(this.paginate.current_page);
                    // this.$store
                    //   .dispatch(
                    //     "accept_tender/getData",
                    //     this.paginate.current_page
                    //   )
                    //   .then((res) => {
                    //     this.isLoading = false;
                    //     //! เลข no table
                    //     res.data.data = res.data.data.map((data, i) => ({
                    //       ...data,
                    //       no:
                    //         (res.data.current_page - 1) * res.data.per_page +
                    //         i +
                    //         1,
                    //     }));
                    //     this.dataAcceptTender = res.data.data;
                    //     this.paginate.current_page = res.data.current_page;
                    //     this.paginate.last_page = res.data.last_page;
                    //   });
                  });
              })
              .catch((error) => {
                this.isLoading = false;
              });
          }
        });
    },

    print() {
      if (this.tenders_id.length === 1) {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You need to Print Tender of your select. !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Print it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.isLoading = true;
              this.$store
                .dispatch("accept_tender/print", this.tenders_id)
                .then((res) => {
                  this.isLoading = false;
                  window.open(res.data);
                })
                .catch((error) => {
                  this.isLoading = false;
                });
            }
          });
      } else {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select 1 order before Print",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },

    // Change Status Accept Tender
    onChangeStatusJobAcceptTender(val) {
      if (this.accept_tenders.length > 0) {
        const item = this.accept_tenders.map((data) => ({
          accept_tender_id: data.accept_tender_id,
          driver_id: data.driver_id,
        }));

        this.$swal
          .fire({
            title: "Are you sure?",
            text: "Do You Want to Change Status Accept Tender ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Change it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.isLoading = true;
              this.isClearSelected = true;
              this.$store
                .dispatch("accept_tender/changeStatusJobAcceptTender", {
                  status: val,
                  accept_tender_id: item,
                })
                .then((res) => {
                  this.isLoading = false;
                  this.isClearSelected = false;
                  this.$swal
                    .fire({
                      icon: "success",
                      title: "Change successfully!",
                      showConfirmButton: false,
                      timer: 1500,
                    })
                    .then(() => {
                      this.isLoading = true;
                      this.$store
                        .dispatch(
                          "accept_tender/getData",
                          this.paginate.current_page
                        )
                        .then((res) => {
                          this.isLoading = false;
                          //! เลข no table
                          res.data.data = res.data.data.map((data, i) => ({
                            ...data,
                            no:
                              (res.data.current_page - 1) * res.data.per_page +
                              i +
                              1,
                          }));
                          this.dataAcceptTender = res.data.data;
                          this.paginate.current_page = res.data.current_page;
                          this.paginate.last_page = res.data.last_page;
                        });
                    });
                })
                .catch((error) => {
                  this.isLoading = false;
                });
            }
          });
      } else {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select 1 order before click button",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },

    // Create cost / truck
    createCostPerTruck() {
      // cost_per_truck
      if (this.accept_tenders.length > 0) {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "Do You Want to Create Cost/Truck ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Create it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.isLoading = true;
              this.isClearSelected = true;
              this.$store
                .dispatch("cost_per_truck/save", this.accept_tenders)
                .then((res) => {
                  this.isLoading = false;
                  if (!res.data.success) {
                    this.$swal.fire({
                      icon: "error",
                      title: "Save Failed",
                      text: res.data.data.error,
                    });
                  } else {
                    this.$swal
                      .fire({
                        icon: "success",
                        title: "Create Cost/Truck successfully!",
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      .then(() => {
                        this.isLoading = true;
                        this.getData(1).then((res) => {
                          this.isLoading = false;
                        });
                      });
                  }
                  this.isClearSelected = false;
                })
                .catch((error) => {
                  this.isClearSelected = false;
                  this.isLoading = false;
                });
            }
          });
      } else {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select 1 order before click button",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },

    // Get Master data
    async getMasterData() {
      const table = [
        "importer_exporter",
        "customers",
        "wh_factory",
        "cy_place",
      ];
      const res = await this.$store.dispatch(
        "master_data/getMasterData",
        table
      );
      this.importerExporter = res.data[0].importer_exporter;
      this.customer = res.data[1].customers;
      this.factoryList = res.data[2].wh_factory;
      this.cyPlaceList = res.data[3].cy_place;
      return res;
    },

    // Get Truck Supply Active
    async getTruckSupplyActive() {
      const res = await this.$store.dispatch(
        "truck_supply/getTruckSupplyActive"
      );
      res.data.forEach((data) => {
        if (data.drivers) {
          this.driverList.push({
            driver_id:
              data.drivers && data.drivers.driver_id
                ? data.drivers.driver_id
                : "",
            name:
              (data.drivers && data.drivers.carrier && data.drivers.carrier.name
                ? data.drivers.carrier.name + " : "
                : "") +
              data.drivers?.f_name +
              " " +
              (data.drivers?.l_name + !data.truck_plate_no &&
              !data.tail_plate_no
                ? ""
                : " (" +
                  data.truck_plate_no +
                  " , " +
                  data.tail_plate_no +
                  ")"),
            driver_name: data.drivers?.f_name + " " + data.drivers?.l_name,
            truck_supply_id: data.truck_supply_id,
            truck_plate_no: data.truck_plate_no,
            tail_plate_no: data.tail_plate_no,
            carrier_id: data.carrier_id ? data.carrier_id : "",
          });
        }

        this.truckLicenseList.push({
          carrier_id: data.carrier_id ? data.carrier_id : "",
          truck_plate_no: data.truck_plate_no,
          truck_supply_id: data.truck_supply_id,
        });
        this.trailerLicenseList.push({
          carrier_id: data.carrier_id ? data.carrier_id : "",
          tail_plate_no: data.tail_plate_no,
        });
      });
      return res.data;
    },
    onCancel() {
      this.isLoading = true;
      this.$store
        .dispatch("accept_tender/getData", this.paginate.current_page)
        .then((res) => {
          this.isLoading = false;
          //! เลข no table
          res.data.data = res.data.data.map((data, i) => ({
            ...data,
            no: (res.data.current_page - 1) * res.data.per_page + i + 1,
          }));
          this.dataAcceptTender = res.data.data;
          this.paginate.current_page = res.data.current_page;
          this.paginate.last_page = res.data.last_page;
        });
    },

    exportExcel() {
      if (this.selectItem.length === 0) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select at least 1 item",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        //! set data and check mode
        this.setDataExcel(this.selectItem);
        if (this.checkData) {
          this.$swal
            .fire({
              title: "Are you sure?",
              text: `Do you want to export Order to Excel?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Export!",
            })
            .then((result) => {
              if (result.isConfirmed) {
                const dataWS = XLSX.utils.json_to_sheet(this.dataExcel);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, dataWS);
                const fileName = `Accept_Tender_${
                  this.selectItem[0].order_detail.order.mode
                }_${moment(new Date()).format("DD_MM_YYYY_h_mm_ss")}.xlsx`;
                XLSX.writeFile(wb, fileName);
                this.isClearSelected = false;
              } else {
                this.dataExcel = [];
                this.isClearSelected = false;
              }
            });
        }
      }
    },
    setDataExcel(items) {
      let mode = items[0].order_detail.order.mode;
      let no = 1;
      const arr = items.sort();

      // * กรณีข้อมมูลเป็นโหมด Import
      if (mode === "Import") {
        try {
          items.forEach((data) => {
            if (mode !== data.order_detail.order.mode) {
              this.$swal.fire({
                title: "Something went wrong!",
                text: `Please select the same mode. (${mode})`,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
              this.isClearSelected = false;
              throw "error";
            } else {
              if (data.accept_tender.length > 0) {
                data.accept_tender.forEach((item) => {
                  this.dataExcel.push({
                    "No.": no,
                    "Mode of Transport ": data.order_detail.order.mode,
                    "CS* ": data.order_detail.order.user_name,
                    "Remark ": data.order_detail.order.remark,
                    "CUSTOMER ": data.order_detail.order.customer_name,
                    "IMPORTER* ": data.order_detail.order.importer_name,
                    "Goods* ": data.order_detail.order.goods_name,
                    "Inv./PO NO.*": data.order_detail.order.inv_po,
                    "ATA ": data.order_detail.order.ata
                      ? moment(data.order_detail.order.ata).format("DD/MM/YYYY")
                      : "",
                    "วันที่ตู้ครบ ": data.order_detail.order.discharge_complete
                      ? moment(
                          data.order_detail.order.discharge_complete
                        ).format("DD/MM/YYYY")
                      : "",
                    "AGENT* ": data.order_detail.order.shipping_agent_name,
                    "Discharge PORT* ":
                      data.order_detail.order.port_discharge_name,
                    "Release Place* ":
                      data.order_detail.order.release_port_name,
                    "Release Date* ": data.order_detail.order.release_date
                      ? moment(data.order_detail.order.release_date).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    "Qty ": data.qty,
                    "Size+ISO Type ":
                      data.order_detail?.container_size_name +
                      "'" +
                      data.order_detail?.container_isotype_name,
                    "Shipping ": data.order_detail.order.shipping_name,
                    "Status Container":
                      data.order_detail.order.container_status,
                    "Model ": data.model_name,
                    "WH/Factory1* ": data.order_detail.wh_factory_1_name,
                    "UnloadDate1* ": data.order_detail.unload_load_date1
                      ? moment(data.order_detail.unload_load_date1).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    "UnloadTime1* ": this.subStringTime(
                      data.order_detail.unload_load_time1
                    ),
                    "WH/Factory2* ": data.order_detail.wh_factory_2_name,
                    "UnloadDate2* ": data.order_detail.unload_load_date2
                      ? moment(data.order_detail.unload_load_date2).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    "UnloadTime2* ": this.subStringTime(
                      data.order_detail.unload_load_time2
                    ),
                    "Haulage ": data.carrier_name
                      ? data.carrier_name
                      : data.order_detail.order.haulage_name,
                    "Tender Date ": data.updated_at
                      ? moment(data.updated_at).format("DD/MM/YYYY")
                      : "",
                    "Driver ": item.driver_name,
                    "Assign Driver Date ": item.updated_at
                      ? moment(item.updated_at).format("DD/MM/YYYY")
                      : "",
                    "RETURN PLACE* ": data.order_detail.return_place_name,
                    "RENT ": data.order_detail.order.rent
                      ? moment(data.order_detail.order.rent).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    "DEM ": data.order_detail.order.demurage
                      ? moment(data.order_detail.order.demurage).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    "DET ": data.order_detail.order.detention
                      ? moment(data.order_detail.order.detention).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    "DET(Days) ": data.order_detail.order.detention_days,
                    "Status (Pending,Complete) ": item.job_status_name,
                    "Status (Complete) Date ": item.complete_date
                      ? moment(item.complete_date).format("DD/MM/YYYY")
                      : "",
                  });
                });
              } else {
                this.dataExcel.push({
                  "No.": no,
                  "Mode of Transport ": data.order_detail.order.mode,
                  "CS* ": data.order_detail.order.user_name,
                  "Remark ": data.order_detail.order.remark,
                  "CUSTOMER ": data.order_detail.order.customer_name,
                  "IMPORTER* ": data.order_detail.order.importer_name,
                  "Goods* ": data.order_detail.order.goods_name,
                  "Inv./PO NO.*": data.order_detail.order.inv_po,
                  "ATA ": data.order_detail.order.ata
                    ? moment(data.order_detail.order.ata).format("DD/MM/YYYY")
                    : "",
                  "วันที่ตู้ครบ ": data.order_detail.order.discharge_complete
                    ? moment(data.order_detail.order.discharge_complete).format(
                        "DD/MM/YYYY"
                      )
                    : "",
                  "AGENT* ": data.order_detail.order.shipping_agent_name,
                  "Discharge PORT* ":
                    data.order_detail.order.port_discharge_name,
                  "Release Place* ": data.order_detail.order.release_port_name,
                  "Release Date* ": data.order_detail.order.release_date
                    ? moment(data.order_detail.order.release_date).format(
                        "DD/MM/YYYY"
                      )
                    : "",
                  "Qty ": data.qty,
                  "Size+ISO Type ":
                    data.order_detail?.container_size_name +
                    "'" +
                    data.order_detail?.container_isotype_name,
                  "Shipping ": data.order_detail.order.shipping_name,
                  "Status Container": data.order_detail.order.container_status,
                  "Model ": data.model_name,
                  "WH/Factory1* ": data.order_detail.wh_factory_1_name,
                  "UnloadDate1* ": data.order_detail.unload_load_date1
                    ? moment(data.order_detail.unload_load_date1).format(
                        "DD/MM/YYYY"
                      )
                    : "",
                  "UnloadTime1* ": this.subStringTime(
                    data.order_detail.unload_load_time1
                  ),
                  "WH/Factory2* ": data.order_detail.wh_factory_2_name,
                  "UnloadDate2* ": data.order_detail.unload_load_date2
                    ? moment(data.order_detail.unload_load_date2).format(
                        "DD/MM/YYYY"
                      )
                    : "",
                  "UnloadTime2* ": this.subStringTime(
                    data.order_detail.unload_load_time2
                  ),
                  "Haulage ": data.carrier_name
                    ? data.carrier_name
                    : data.order_detail.order.haulage_name,
                  "Tender Date ": data.updated_at
                    ? moment(data.updated_at).format("DD/MM/YYYY")
                    : "",
                  "Driver ": "-",
                  "Assign Driver Date ": "-",
                  "RETURN PLACE* ": data.order_detail.return_place_name,
                  "RENT ": data.order_detail.order.rent
                    ? moment(data.order_detail.order.rent).format("DD/MM/YYYY")
                    : "",
                  "DEM ": data.order_detail.order.demurage
                    ? moment(data.order_detail.order.demurage).format(
                        "DD/MM/YYYY"
                      )
                    : "",
                  "DET ": data.order_detail.order.detention
                    ? moment(data.order_detail.order.detention).format(
                        "DD/MM/YYYY"
                      )
                    : "",
                  "DET(Days) ": data.order_detail.order.detention_days,
                  "Status (Pending,Complete) ": "-",
                  "Status (Complete) Date ": "-",
                });
              }
              no++;
            }
          });
        } catch (e) {
          this.checkData = false;
        }
      }
      // * กรณีข้อมมูลเป็นโหมด Export และอื่นๆ
      else {
        try {
          items.forEach((data) => {
            if (mode !== data.order_detail.order.mode) {
              this.$swal.fire({
                title: "Something went wrong!",
                text: `Please select the same mode. (${mode})`,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
              this.isClearSelected = true;
              throw "error";
            } else {
              if (data.accept_tender.length > 0) {
                data.accept_tender.forEach((item) => {
                  this.dataExcel.push({
                    "No.": no,
                    "Mode of Transport ": data.order_detail.order.mode,
                    "CS* ": data.order_detail.order.user_name,
                    "Remark ": data.order_detail.order.remark,
                    "CUSTOMER ": data.order_detail.order.customer_name,
                    "EXPORTER* ": data.order_detail.order.exporter_name,
                    "Goods* ": data.order_detail.order.goods_name,
                    "Package ": data.order_detail.order.package_name,
                    "Inv./PO NO.*": data.order_detail.order.inv_po,
                    "Booking no.*": data.order_detail.order.booking_no,
                    "CY PLACE*": data.order_detail.cy_place_name,
                    "CY DATE*": data.order_detail.order.cy_date
                      ? moment(data.order_detail.order.cy_date).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    "Shipping Agent* ":
                      data.order_detail.order.shipping_agent_name,
                    "Qty ": data.qty,
                    "Size+ISO Type ":
                      data.order_detail?.container_size_name +
                      "'" +
                      data.order_detail?.container_isotype_name,
                    "Status Container":
                      data.order_detail.order.container_status,
                    "Model ": data.model_name,
                    "WH/Factory1* ": data.order_detail.wh_factory_1_name,
                    "UnloadDate1* ": data.order_detail.unload_load_date1
                      ? moment(data.order_detail.unload_load_date1).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    "UnloadTime1* ": this.subStringTime(
                      data.order_detail.unload_load_time1
                    ),
                    "WH/Factory2* ": data.order_detail.wh_factory_2_name,
                    "UnloadDate2* ": data.order_detail.unload_load_date2
                      ? moment(data.order_detail.unload_load_date2).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    "UnloadTime2* ": this.subStringTime(
                      data.order_detail.unload_load_time2
                    ),
                    "Ton ": data.order_detail.order.ton,
                    "Forwarder ": data.order_detail.order.forwarder_name,
                    "FEEDER VESSEL* ": data.order_detail.order.feeder_vessel,
                    "OCEAN VESSEL ": data.order_detail.order.ocean_vessel,
                    "PORT/Country* ": data.order_detail.order.port_country_name,
                    "RETURN PLACE* ": data.order_detail.order.return_place_name,
                    "CLOSING DATE* ": data.order_detail.order.closing_date
                      ? moment(data.order_detail.order.closing_date).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    "CLOSING TIME* ": this.subStringTime(
                      data.order_detail.order.closing_time
                    ),
                    "FRD* ": data.order_detail.order.frd
                      ? moment(data.order_detail.order.frd).format("DD/MM/YYYY")
                      : "",
                    "SHIPPING ": data.order_detail.order.shipping_name,
                    "Haulage ": data.carrier_name
                      ? data.carrier_name
                      : data.order_detail.order.haulage_name,
                    "Tender Date ": data.updated_at
                      ? moment(data.updated_at).format("DD/MM/YYYY")
                      : "",
                    "Driver ": item.driver_name,
                    "Assign Driver Date ": item.updated_at
                      ? moment(item.updated_at).format("DD/MM/YYYY")
                      : "",
                    "SHORE ": data.order_detail.order.shore_name,
                    "Fumigate ": data.order_detail.order.fumigate_name,
                    "Survey ": data.order_detail.order.survey_name,
                    "Others ": data.order_detail.order.others_name,
                    "SI CUT OFF DATE ": data.order_detail.order.si_cut_off_date
                      ? moment(data.order_detail.order.si_cut_off_date).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    "SI CUT OFF TIME ": this.subStringTime(
                      data.order_detail.order.si_cut_off_time
                    ),
                    "VGM CUT OFF DATE ": data.order_detail.order
                      .vgm_cut_off_date
                      ? moment(data.order_detail.order.vgm_cut_off_date).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    "VGM CUT OFF TIME ": this.subStringTime(
                      data.order_detail.order.vgm_cut_off_time
                    ),
                    "Status (Pending,Complete) ": item.job_status_name,
                    "Status (Complete) Date ": item.complete_date
                      ? moment(item.complete_date).format("DD/MM/YYYY")
                      : "",
                  });
                });
              } else {
                this.dataExcel.push({
                  "No.": no,
                  "Mode of Transport ": data.order_detail.order.mode,
                  "CS* ": data.order_detail.order.user_name,
                  "Remark ": data.order_detail.order.remark,
                  "CUSTOMER ": data.order_detail.order.customer_name,
                  "EXPORTER* ": data.order_detail.order.exporter_name,
                  "Goods* ": data.order_detail.order.goods_name,
                  "Package ": data.order_detail.order.package_name,
                  "Inv./PO NO.*": data.order_detail.order.inv_po,
                  "Booking no.*": data.order_detail.order.booking_no,
                  "CY PLACE*": data.order_detail.cy_place_name,
                  "CY DATE*": data.order_detail.order.cy_date
                    ? moment(data.order_detail.order.cy_date).format(
                        "DD/MM/YYYY"
                      )
                    : "",
                  "Shipping Agent* ":
                    data.order_detail.order.shipping_agent_name,
                  "Qty ": data.qty,
                  "Size+ISO Type ":
                    data.order_detail?.container_size_name +
                    "'" +
                    data.order_detail?.container_isotype_name,
                  "Status Container": data.order_detail.order.container_status,
                  "Model ": data.model_name,
                  "WH/Factory1* ": data.order_detail.wh_factory_1_name,
                  "UnloadDate1* ": data.order_detail.unload_load_date1
                    ? moment(data.order_detail.unload_load_date1).format(
                        "DD/MM/YYYY"
                      )
                    : "",
                  "UnloadTime1* ": this.subStringTime(
                    data.order_detail.unload_load_time1
                  ),
                  "WH/Factory2* ": data.order_detail.wh_factory_2_name,
                  "UnloadDate2* ": data.order_detail.unload_load_date2
                    ? moment(data.order_detail.unload_load_date2).format(
                        "DD/MM/YYYY"
                      )
                    : "",
                  "UnloadTime2* ": this.subStringTime(
                    data.order_detail.unload_load_time2
                  ),
                  "Ton ": data.order_detail.order.ton,
                  "Forwarder ": data.order_detail.order.forwarder_name,
                  "FEEDER VESSEL* ": data.order_detail.order.feeder_vessel,
                  "OCEAN VESSEL ": data.order_detail.order.ocean_vessel,
                  "PORT/Country* ": data.order_detail.order.port_country_name,
                  "RETURN PLACE* ": data.order_detail.order.return_place_name,
                  "CLOSING DATE* ": data.order_detail.order.closing_date
                    ? moment(data.order_detail.order.closing_date).format(
                        "DD/MM/YYYY"
                      )
                    : "",
                  "CLOSING TIME* ": this.subStringTime(
                    data.order_detail.order.closing_time
                  ),
                  "FRD* ": data.order_detail.order.frd
                    ? moment(data.order_detail.order.frd).format("DD/MM/YYYY")
                    : "",
                  "SHIPPING ": data.order_detail.order.shipping_name,
                  "Haulage ": data.carrier_name
                    ? data.carrier_name
                    : data.order_detail.order.haulage_name,
                  "Tender Date ": data.updated_at
                    ? moment(data.updated_at).format("DD/MM/YYYY")
                    : "",
                  "Driver ": "-",
                  "Assign Driver Date ": "-",
                  "SHORE ": data.order_detail.order.shore_name,
                  "Fumigate ": data.order_detail.order.fumigate_name,
                  "Survey ": data.order_detail.order.survey_name,
                  "Others ": data.order_detail.order.others_name,
                  "SI CUT OFF DATE ": data.order_detail.order.si_cut_off_date
                    ? moment(data.order_detail.order.si_cut_off_date).format(
                        "DD/MM/YYYY"
                      )
                    : "",
                  "SI CUT OFF TIME ": this.subStringTime(
                    data.order_detail.order.si_cut_off_time
                  ),
                  "VGM CUT OFF DATE ": data.order_detail.order.vgm_cut_off_date
                    ? moment(data.order_detail.order.vgm_cut_off_date).format(
                        "DD/MM/YYYY"
                      )
                    : "",
                  "VGM CUT OFF TIME ": this.subStringTime(
                    data.order_detail.order.vgm_cut_off_time
                  ),
                  "Status (Pending,Complete) ": "-",
                  "Status (Complete) Date ": "-",
                });
              }
              no++;
            }
          });
        } catch (e) {
          this.checkData = false;
        }
      }
    },

    onHandleToPending(item) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You want to change status Draft to Pending",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.$store
              .dispatch("accept_tender/toPending", {
                accept_tender_id: item.accept_tender_id,
                status: "Pending",
                driver_id: item.driver_id,
                qty: item.qty,
              })
              .then((res) => {
                this.isLoading = false;
                if (res.data.status == "success") {
                  this.$swal
                    .fire({
                      title: "Success!",
                      text: "Change status Draft to Pending",
                      icon: "success",
                      showCancelButton: false,
                      confirmButtonColor: "#3085d6",
                      confirmButtonText: "OK",
                    })
                    .then((result) => {
                      if (result.value) {
                        this.isLoading = true;
                        this.$store
                          .dispatch(
                            "accept_tender/getData",
                            this.paginate.current_page
                          )
                          .then((res) => {
                            this.isLoading = false;
                            //! เลข no table
                            res.data.data = res.data.data.map((data, i) => ({
                              ...data,
                              no:
                                (res.data.current_page - 1) *
                                  res.data.per_page +
                                i +
                                1,
                            }));
                            this.dataAcceptTender = res.data.data;
                            this.paginate.current_page = res.data.current_page;
                            this.paginate.last_page = res.data.last_page;
                          });
                      }
                    });
                } else {
                  this.$swal.fire({
                    title: "Something went wrong!",
                    text: "Can't change status",
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "OK",
                  });
                }
              })
              .catch((err) => {
                this.isLoading = false;
                this.$swal.fire({
                  title: "Something went wrong!",
                  text: "Can't change status",
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "OK",
                });
              });
          }
        });
    },
  },
};
</script>

<style></style>
