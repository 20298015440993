var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-tender"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"flat":"","tile":"","color":"#C4C4C4"}},[_c('v-form',{ref:"form",staticClass:"px-6",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{staticClass:"pa-6"},[_c('v-flex',[_c('v-layout',[_c('v-flex',{attrs:{"xs3":""}}),_c('v-flex',{attrs:{"xs3":""}},[_vm._v(" ท่าเรือ : ")]),_c('v-flex',{attrs:{"xs3":""}},[_vm._v(" ค่าผ่านท่า : ")]),_c('v-flex',{attrs:{"xs3":""}},[_vm._v(" ค่าชั่ง : ")])],1),_c('v-layout',[_c('v-flex',{attrs:{"xs3":""}},[_c('v-btn',{attrs:{"color":"#338A68","dark":""},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus-circle-outline")]),_c('span',{staticClass:"text-capitalize font-weight-black"},[_vm._v("Add")])],1)],1),_c('v-flex',{attrs:{"xs3":""}},[_c('AutocompleteInput',{staticClass:"pr-12",attrs:{"hideDetails":"","model":_vm.form.port_discharge_id,"rulse":"fieldRequire","items":_vm.portDichargeList,"itemText":"port_discharge_name","itemValue":"port_discharge_id"},on:{"update-data":function($event){_vm.form.port_discharge_id = $event
                        ? $event.port_discharge_id
                        : '';
                      _vm.form.port_discharge_name = $event
                        ? $event.port_discharge_name
                        : '';}}})],1),_c('v-flex',{attrs:{"xs3":""}},[_c('TextInput',{staticClass:"pr-12",attrs:{"type":"number","hideDetails":"","model":_vm.form.port_fee,"rulse":"fieldRequire"},on:{"update-data":function($event){_vm.form.port_fee = $event}}})],1),_c('v-flex',{attrs:{"xs3":""}},[_c('TextInput',{staticClass:"pr-12",attrs:{"type":"number","hideDetails":"","model":_vm.form.scale_fee,"rulse":"fieldRequire"},on:{"update-data":function($event){_vm.form.scale_fee = $event}}})],1)],1)],1)],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [(_vm.isEdit && index == _vm.indexItem)?_c('tr',[_c('td',{staticClass:"text-center "},[_c('v-layout',[_c('v-flex',[_c('v-btn',{attrs:{"fab":"","depressed":"","small":"","text":""},on:{"click":function($event){return _vm.cancle()}}},[_c('v-icon',{attrs:{"mx-2":"","color":"red"}},[_vm._v(" mdi-close-circle-outline ")])],1)],1),_c('v-flex',[_c('v-btn',{attrs:{"fab":"","depressed":"","small":"","text":""},on:{"click":function($event){return _vm.update(item)}}},[_c('v-icon',{staticClass:"mx-2",attrs:{"color":"#338A68"}},[_vm._v(" mdi-content-save-outline ")])],1)],1)],1)],1),_c('td',{staticClass:" border-left"},[_vm._v(_vm._s(item.no))]),_c('td',{},[_c('AutocompleteInput',{staticClass:"pr-12",attrs:{"hideDetails":"","placeholder":"Customer","model":item.port_discharge_id,"rulse":"fieldRequire","items":_vm.portDichargeList,"itemText":"port_discharge_name","itemValue":"port_discharge_id"},on:{"update-data":function($event){item.port_discharge_id = $event ? $event.port_discharge_id : '';
              item.port_discharge_name = $event
                ? $event.port_discharge_name
                : '';}}})],1),_c('td',{},[_c('TextInput',{staticClass:"pr-12",attrs:{"hideDetails":"","placeholder":"Job no.","model":item.port_fee,"rulse":"fieldRequire"},on:{"update-data":function($event){item.port_fee = $event}}})],1),_c('td',{},[_c('TextInput',{staticClass:"pr-12",attrs:{"inputRule":"number","hideDetails":"","placeholder":"Job no.","model":item.scale_fee,"rulse":"fieldRequire"},on:{"update-data":function($event){item.scale_fee = $event}}})],1)]):_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-layout',[_c('v-flex',[_c('v-btn',{attrs:{"fab":"","depressed":"","small":"","text":""},on:{"click":function($event){return _vm.deleteItem(item, index)}}},[_c('v-icon',{attrs:{"disabled":_vm.isEdit,"color":"#000"}},[_vm._v(" mdi-delete-outline ")])],1)],1),_c('v-flex',[_c('v-btn',{attrs:{"fab":"","depressed":"","small":"","color":"#62C4E3","text":""},on:{"click":function($event){return _vm.edit(item, index)}}},[_c('v-icon',{attrs:{"disabled":_vm.isEdit}},[_vm._v(" mdi-pencil-outline ")])],1)],1)],1)],1),_c('td',{staticClass:" border-left"},[_vm._v(_vm._s(item.no))]),_c('td',{},[_vm._v(_vm._s(item.port_discharge_name))]),_c('td',{},[_vm._v(_vm._s(item.port_fee))]),_c('td',{},[_vm._v(_vm._s(item.scale_fee))])])]}}])}),_c('v-layout',{attrs:{"mt-4":""}},[_c('v-flex',[_c('Paginate',{attrs:{"current_page":_vm.paginate.current_page,"last_page":_vm.paginate.last_page,"nextPage":_vm.onHandlePaginate}})],1)],1),_c('Loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }