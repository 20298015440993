import AuthService from "../AuthService";

const acceptTender = {
  getData(page) {
    return AuthService.get(`/api/accept-tender?page=${page}`).then(
      (res) => res
    );
  },
  getDataById(id) {
    return AuthService.get(`/api/accept-tender/${id}`).then((res) => res);
  },

  save(data) {
    return AuthService.post("/api/accept-tender/save", data).then((res) => res);
  },
  update(data) {
    return AuthService.post(`/api/accept-tender/update/${data.id}`, data).then(
      (res) => res
    );
  },
  delete(id) {
    return AuthService.post(`/api/accept-tender/delete/${id}`).then(
      (res) => res
    );
  },
  changeStatus(data) {
    return AuthService.post(`/api/accept-tender/change-status`, data).then(
      (res) => res
    );
  },
  changeStatusJobAcceptTender(data) {
    return AuthService.post(
      `/api/accept-tender/change-status-job-accept-tender`,
      data
    ).then((res) => res);
  },
  toPending(data) {
    return AuthService.post(`/api/accept-tender/to-pending`, data).then(
      (res) => res
    );
  },
  print(data) {
    return AuthService.post(`/api/accept-tender-print/`, data).then(
      (res) => res
    );
  },
  search(data) {
    return AuthService.get(
      `/api/accept-tender-search?page=${data.page}&order_id=${
        data.order_id
      }&inv_po=${data.inv_po}&booking_no=${data.booking_no}&customer_id=${
        data.customer_id
      }&importer_exporter_id=${
        data.importer_exporter_id
      }&create_update_accept_tender_from=${
        data.create_update_accept_tender_from
      }&create_update_accept_tender_to=${
        data.create_update_accept_tender_to
      }&wh_factory_id=${data.wh_factory_id}&load_and_unload_date_from=${
        data.load_and_unload_date_from
      }&load_and_unload_date_to=${
        data.load_and_unload_date_to
      }&mode=${JSON.stringify(data.mode)}&pending_job=${
        data.pending_job
      }&new_job_today=${data.new_job_today}&non_tender=${
        data.non_tender
      }&driver_name=${data.driver_name}&cy_date_from=${
        data.cy_date_from
      }&cy_date_to=${data.cy_date_to}&cy_place_id=${data.cy_place_id}`
    ).then((res) => res);
  },
};

export default acceptTender;
